.editext-btn {
	border-radius: 1px;
	outline: none;
	padding: 0.6em;
	min-width: 30px;
	height: 100%;
	border-color: rgb(216, 216, 216) rgb(209, 209, 209) rgb(186, 186, 186);
	border-style: solid;
	border-width: 1px;
	color: #000;
}

div .editext-btn:parent {
	display: none;
}

#popper-hide {
	display: none;
}

.react-editext:hover + div > button {
	background-color: aqua;
	display: block;
}

.my-custom-view-wrapper {
	display: flex;
	flex-direction: column;
}

.my-custom-view-wrapper div:last-child {
	/* Here we are styling the button container. */
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin-top: 5px;
}

.min-h-450 {
	min-height: 45rem;
}
.h-450 {
	height: 45rem;
}

.min-h-500 {
	min-height: 50rem;
}
.h-500 {
	height: 50rem;
}

/*****
** Anatomie
****/
.canvas svg.tool--circ {
	cursor: crosshair;
}

.canvas svg.tool--drag {
	cursor: default;
}

.canvas svg *:hover {
	stroke: dodgerblue;
	stroke-width: 2px;
}

.ck.ck-toolbar.ck-toolbar_grouping > .ck-toolbar__items {
	flex-wrap: wrap;
}

.ck-content {
	min-height: 200px;
}

.CKEditor {
	margin: 2%;
	width: 90%;
}

.ck.ck-dropdown .ck-button.ck-dropdown__button:not {
	display: none;
}

.ck.ck-content ul,
.ck.ck-content ul li {
	list-style-type: inherit;
}

.ck.ck-content ul {
	/* Default user agent stylesheet, you can change it to your needs. */
	padding-left: 40px;
}

.ck.ck-content ol,
.ck.ck-content ol li {
	list-style-type: decimal;
}
.ck.ck-content ol {
	/* Default user agent stylesheet, you can change it to your needs. */
	padding-left: 40px;
}
